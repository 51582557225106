<template>
  <section class="top-ptable">
    <div class="top-ptable__header-container-ins">
      <template v-if="!teamOrplayer"
        >Haz click en cualquier
        <template v-if="tournamentSelected.id == 1001"
          ><template v-if="category == 22">portera</template><template v-else>jugadora</template></template
        ><template v-else
          ><template v-if="category == 22">portero</template><template v-else>jugador</template></template
        ></template
      >
      <template v-else>Haz click en cualquier equipo</template>
    </div>
    <div class="top-ptable__header" :style="`background-color: #EDF4F9;`">
      <span :style="`color: ${customValues.color}`">POS</span>
      <span :style="`color: ${customValues.color}`">
        <template v-if="!teamOrplayer"
          ><template v-if="tournamentSelected.id == 1001"
            ><template v-if="category == 22">PORTERA</template><template v-else>JUGADORA</template></template
          ><template v-else
            ><template v-if="category == 22">PORTERO</template><template v-else>JUGADOR</template></template
          ></template
        >
        <template v-else>EQUIPO</template>
      </span>
      <span :style="{ color: customValues.color, 'text-align': 'left;' }">TOTAL</span>
    </div>
    <div v-for="(player, i) in playerData" :key="i" class="top-ptable__row row-user">
      <span class="row-user__number"> {{ i + 1 + topValue * 10 }}. </span>
      <div class="row-user__info" @click="sendToProfile(player.id)">
        <img v-if="viewImage == indexPage" :src="player.img" :alt="player.name" />
        <span>{{ player.name }}</span>
      </div>
      <span class="row-user__total">
        {{ player.total }}
      </span>
    </div>
  </section>
</template>

<script>
import { clickTopsAnalisisDeEquipo, clickTopsAnalisisDeJugador } from '@/utils/analytics';
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'PlayerTable',
  props: {
    playerData: {
      type: Array,
      required: true,
    },
    viewImage: {
      type: Number,
      required: true,
    },
    category: {
      type: Number,
      require: true,
    },
    topValue: {
      type: Number,
      required: true,
    },
    indexPage: {
      type: Number,
      required: true,
    },
    customValues: {
      type: Object,
      default: () => {},
    },
    teamOrplayer: {
      type: Boolean,
      default: false,
    },
    season: {
      type: Number,
      default: 1401,
    },
  },
  computed: {
    ...mapState('general', ['tournamentSelected']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  methods: {
    sendToProfile(id) {
      if (this.teamOrplayer) {
        clickTopsAnalisisDeEquipo({
          team_id: id,
          season_id: this.season,
          is_premium: this.getPremiumAccount,
          golstats: this.getIsGolstats,
        });
      } else {
        clickTopsAnalisisDeJugador({
          player_id: id,
          season_id: this.season,
          is_premium: this.getPremiumAccount,
          golstats: this.getIsGolstats,
        });
      }
      if (this.$route.name === 'topsSection') {
        if (this.teamOrplayer) {
          this.$router.push({ name: 'teamProfile', query: { team: id, season: this.season, isLanding: true } });
        } else {
          this.$router.push({ name: 'playerProfile', query: { player: id, season: this.season, isLanding: true } });
        }
      } else {
        if (this.teamOrplayer) {
          let routeData = this.$router.resolve({ name: 'teamprofile', query: { team: id, season: this.season } });
          window.open(routeData.href, '_blank');
        } else {
          let routeData = this.$router.resolve({ name: 'playerprofile', query: { player: id, season: this.season } });
          window.open(routeData.href, '_blank');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top-ptable {
  width: 100%;
  margin-bottom: 10px;

  &__header-container-ins {
    width: 100%;
    background: #fff;
    height: 32px;
    font-family: 'Circular-Std-Medium';
    color: #132839;
    font-size: 14px;
    text-align: center;
    line-height: 33px;
  }
  &__header,
  &__row {
    display: grid;
    grid-template-columns: minmax(25px, 30px) 1fr minmax(40px, 50px);
    border-radius: 2.4px;
    align-items: center;
  }
  &__row:nth-child(even) {
    background-color: #f5f5f5;
  }

  &__row:nth-child(odd) {
    background-color: #ffffff;
  }
  &__header {
    background-color: #dbefca;
    height: 24px;
    span {
      font-family: 'Avenir-Pro-Bold';
      font-size: 12px;
      font-weight: bold;
      letter-spacing: -0.18px;
      text-align: center;
      color: #353535;
    }
  }
}
.row-user {
  color: #545454;
  height: 32px;

  @media screen and (max-width: 500px) {
    margin: 0px 12px;
  }
  &__number {
    font-family: 'Avenir-Pro-Medium';
    font-size: 13.2px;
    font-weight: 500;
    letter-spacing: -0.49px;
  }
  &__info {
    font-family: 'Avenir-Pro-Medium';
    font-size: 13px;
    display: grid;
    align-items: center;
    grid-template-columns: 20px 1fr;
    column-gap: 10px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: underline;
    color: #132839;
    white-space: nowrap;
  }
  &__info:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  &__total {
    font-family: 'Avenir-Pro-Bold';
    font-size: 13.2px;
    font-weight: bold;
    letter-spacing: -0.49px;
    text-align: center;
    padding-right: 15px;

    @media screen and (max-width: 500px) {
      padding-left: 10px;
      padding-right: 0px;
    }
  }
}
</style>
