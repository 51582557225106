<template>
  <SkeletonBlock v-if="isMenuHidden" ref="skeletonBlock"></SkeletonBlock>
  <div v-else class="menu-top">
    <div class="menu-top__header-pc top-header-pc border-detail-top" :style="customValuesHeaderBackground">
      <img :src="customValues.logo" alt="escudo_golstats" class="logo-img" />
      <div class="top-header-pc__left-items"></div>
      <div class="top-header-pc__title" :style="customValuesHeaderColor">
        {{ category.name }}
      </div>
    </div>
    <div class="menu-top__header-pc top-header-pc" :style="customValuesHeader2Background">
      <div class="top-header-pc__left-items"></div>
      <div class="top-header-pc__right-items">
        <template v-if="category.id != 5">
          <BField>
            <span :class="{ active: !isSwitched }">Individual</span>
            <BSwitch
              color="#000000"
              passive-type="is-success"
              type="is-success"
              size="is-small"
              v-model="isSwitched"
            ></BSwitch>
            <span :class="{ active: isSwitched }">Equipo</span>
          </BField>
        </template>
      </div>
      <div class="top-header-pc__left-items"></div>
    </div>
    <div class="menu-top__header top-header" :style="customValuesHeaderBackground">
      <img :src="customValues.logo" alt="escudo_golstats" class="logo-img" />
      <div class="top-header__left-items"></div>
      <div class="top-header__title" :style="customValuesHeaderColor">{{ category.name }}</div>

      <div class="top-header__right-items border-detail-bottom" :style="customValuesHeader2Background">
        <div class="top-header__right-items__background-cont">
          <template v-if="category.id != 5">
            <div class="container-filter-switch">
              <BField position="is-center">
                <span :class="{ active: !isSwitched }">Individual</span>
                <BSwitch passive-type="is-success" type="is-success" size="is-small" v-model="isSwitched"></BSwitch>
                <span :class="{ active: isSwitched }">Equipo</span>
              </BField>
            </div>
          </template>
        </div>
      </div>
    </div>

    <YoureWatching
      @show="show"
      :filters="arrayPredefinedFilters"
      :category="category.id"
      @changeCarousel="changeCarousel"
      :selected="indexPredefinedFiltersSelected"
      :type="typeSelected"
    />
    <div class="container-menu-top-all">
      <div class="table-overlay-container" v-if="!isPremiumUser && bluer">
        <div class="table-overlay--desktop">
          <div class="div-centyer-text">
            <div>
              <span class="text-bold-overlay"><b>Solo para Usuarios Premium</b> </span><br />
              <span class="subtext-bold-overlay">Crea tu cuenta por $99 mxn al mes</span>
            </div>
          </div>
        </div>
      </div>
      <div class="menu-top__body top-body" :class="{ blurred: !isPremiumUser && bluer }">
        <div class="top-body__table">
          <template v-if="statsData.data && topData.length > 0">
            <BCarousel
              :key="updateSlider"
              :autoplay="false"
              :pause-info="false"
              :arrow="false"
              :indicator-inside="false"
              @change="changeItem"
            >
              <BCarouselItem v-for="(carousel, i) in topData" :key="i">
                <section class="player-container">
                  <PlayerTable
                    :playerData="carousel"
                    :topValue="i"
                    :category="category.id"
                    :indexPage="i"
                    :viewImage="viewImage"
                    :season="season"
                    :customValues="customValues"
                    :teamOrplayer="isSwitched"
                  />
                </section>
              </BCarouselItem>
            </BCarousel>
          </template>
          <template v-else>
            <template v-if="!filterActive">
              <template v-if="arePlaysEmpty">
                <div class="top-ptable__header" :style="`background-color: ${customValues.bgColor};`">
                  <span :style="`color: ${customValues.color}`">POS</span>
                  <span :style="`color: ${customValues.color}`">
                    <template v-if="!isSwitched"
                      ><template v-if="tournamentSelected.id == 1001"
                        ><template v-if="category == 22">PORTERA</template
                        ><template v-else>JUGADORA</template></template
                      ><template v-else
                        ><template v-if="category == 22">PORTERO</template
                        ><template v-else>JUGADOR</template>z</template
                      ></template
                    >
                    <template v-else>EQUIPO</template>
                  </span>
                  <span :style="(`color: ${customValues.color}`, `text-align:left;`)">TOTAL</span>
                </div>
                <div class="container-not-found-data">
                  <div class="container-not-found-data__img-search">
                    <img src="/icons/graf_busqueda@2x.png" width="74" />
                  </div>
                  <div class="container-not-found-data__title-info">Sin resultados</div>
                </div>
              </template>
              <template v-else>
                <div class="top-ptable__header" :style="`background-color: ${customValues.bgColor};`">
                  <span :style="`color: ${customValues.color}`">POS</span>
                  <span :style="`color: ${customValues.color}`">
                    <template v-if="!isSwitched"
                      ><template v-if="tournamentSelected.id == 1001"
                        ><template v-if="category == 22">PORTERA</template
                        ><template v-else>JUGADORA</template></template
                      ><template v-else
                        ><template v-if="category == 22">PORTERO</template><template v-else>JUGADOR</template></template
                      ></template
                    >
                    <template v-else>EQUIPO</template>
                  </span>
                  <span :style="(`color: ${customValues.color}`, `text-align:left;`)">TOTAL</span>
                </div>
                <div v-for="index in 10" :key="index" class="placeholder-item" />
              </template>
            </template>
            <template v-if="filterActive">
              <div class="top-ptable__header" :style="`background-color: ${customValues.bgColor};`">
                <span :style="`color: ${customValues.color}`">POS</span>
                <span :style="`color: ${customValues.color}`">
                  <template v-if="!isSwitched"
                    ><template v-if="tournamentSelected.id == 1001"
                      ><template v-if="category == 22">PORTERA</template><template v-else>JUGADORA</template></template
                    ><template v-else
                      ><template v-if="category == 22">PORTERO</template><template v-else>JUGADOR</template></template
                    ></template
                  >
                  <template v-else>EQUIPO</template>
                </span>
                <span :style="(`color: ${customValues.color}`, `text-align:left;`)">TOTAL</span>
              </div>
              <div class="container-not-found-data">
                <div class="container-not-found-data__img-search">
                  <img src="/icons/graf_busqueda@2x.png" width="74" />
                </div>
                <div class="container-not-found-data__title-info">Sin resultados</div>
                <div class="container-not-found-data__subtitle-info">
                  No se encontraron resultados que<br />
                  coincidan con el filtrado.
                </div>
              </div>
            </template>
          </template>
        </div>
        <div class="top-body__filters">
          <FilterTable
            :key="2"
            :category="category.id"
            :customValues="customValues"
            @filters="filters"
            :filtersPredefined="filtersPredefinedComputed"
            :type="typeSelected"
            :is-cup="isCup"
          />
        </div>
      </div>
    </div>
    <div class="menu-top__footer">
      <img src="/top/logos/logo_golstats.svg" alt="logo_golstats" />
      <template v-if="colorsConfig.show_logo && showLogos">
        <div class="spacer" />
        <BetLogo height="28px" width="auto" :url-logo="colorsConfig.secondary_logo" :url-link="colorsConfig.url_base" />
      </template>
    </div>

    <div class="menu-top__drawer" :class="{ active: openLayer }">
      <div class="filter-drawer" :class="{ active: openDrawer }">
        <div class="close-button" @click="hideDrawer">
          <img src="/icons/icn-close-white-no-cicrle.svg" @click="hideDrawer" alt="close" width="25" />
        </div>
        <FilterTable
          :key="1"
          :category="category.id"
          :customValues="customValues"
          @filters="filters"
          :filtersPredefined="filtersPredefinedComputed"
          :type="typeSelected"
          :is-cup="isCup"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import PlayerTable from '@/components/Sections/Top/PlayerTable';
import FilterTable from '@/components/Sections/Top/FilterTable';
import YoureWatching from '@/components/Sections/Top/YoureWatching';
import SkeletonBlock from '@/components/Elements/SkeletonBlock';
import { mapGetters, mapState } from 'vuex';
import { BField } from 'buefy/dist/esm/field';
import { BSwitch } from 'buefy/dist/esm/switch';
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';

export default {
  components: {
    PlayerTable,
    FilterTable,
    SkeletonBlock,
    BField,
    BSwitch,
    YoureWatching,
    BCarousel,
    BCarouselItem,
    BetLogo: () => import('@/components/Elements/BetLogo'),
  },
  props: {
    logo: {
      type: String,
      default: '',
    },
    header: {
      type: Object,
      default: () => {},
    },
    bluer: {
      type: Boolean,
      default: true,
    },
    header2: {
      type: Object,
      default: () => {},
    },
    interiors: {
      type: Object,
      default: () => {},
    },
    category: {
      type: Object,
      default: () => {},
    },
    season: {
      type: Number,
    },
    position: {
      type: Number,
      default: 1,
    },
    isPremiumUser: {
      type: Boolean,
      default: false,
    },
    isCup: {
      type: Boolean,
      default: false,
    },
    showLogos: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    arrayPredefinedFilters: [],
    arrayPredefinedOrigin: [],
    indexPredefinedFiltersSelected: 0,
    predefinedFiltersMatchLapse: [],
    predefinedFiltersPlayingAs: [],
    predefinedFiltersSections: [],
    system: '',
    url: 'https://8grl16o77j.execute-api.us-west-2.amazonaws.com/prod/topsBySeasonAndCategory',
    urlFilter: 'https://cqqxaonvm6.execute-api.us-east-2.amazonaws.com/prod/tops/filters?categories=',
    seasonID: 1401,
    statsData: {},
    zoneType: 1,
    updateCheck: 0,
    tab: 0,
    local: [1, 2],
    results: [1, 2, 3],
    firstTime: [1, 2, 3, 4, 5, 6],
    secondTime: [1, 2],
    zone: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    viewImage: 0,
    typeSelected: 1,
    filterActive: false,
    topsTeams: {},
    topsPlayers: {},
    playersInfo: {},
    teamInfo: {},
    filtersSelect: {
      playingAs: [],
      matchResult: [],
      sections: [],
      zone: [],
      typeZone: [],
    },
    updateSlider: 0,
    isSwitched: false,
    formatStats: [],
    formatFilterStats: [],
    openLayer: false,
    openDrawer: false,
    customValues: {
      logo: '',
      bgColor: '#EDF4F9',
      color: '#fffff',
    },
    customValuesHeaderBackground: {
      background: '#dbefca',
    },
    customValuesHeader2Background: {
      background: '#3b3b3b',
      color: '#FFFFFF',
    },
    customValuesHeaderColor: {
      color: '#cbee6a',
    },
    data_headers: ['team_id', 'player_id', 'playing_as', 'match_result', 'section', 'total', 'origen', 'destino'],
    isMenuHidden: true,
    arePlaysEmpty: false,
    intersectionObserverCounter: 0,
    isVisible: false,
    playersImagesUrl: 'https://az755631.vo.msecnd.net/players-profile/',
  }),
  computed: {
    ...mapState('profileGeneral', ['colorsConfig']),
    ...mapState('general', ['tournamentSelected']),
    ...mapGetters('loginGeneral', ['isLoggedIn']),
    ...mapState({ displayWidth: 'displayWidth' }),
    filtersPredefinedComputed() {
      if (this.arrayPredefinedFilters.length > 0) {
        return this.arrayPredefinedFilters[this.indexPredefinedFiltersSelected].filtersPredefined;
      } else {
        return {
          type: 1,
          sections: this.firstTime,
          matchResult: this.results,
          playingAs: this.local,
          zone: this.zone,
          typeZone: this.zoneType,
        };
      }
    },
    topData() {
      var topTeam = {};
      var topPlayer = {};
      var sortTopTeam = [];
      var sortTopPlayer = [];
      if (Object.entries(this.statsData).length > 0) {
        this.formatFilterStats.map(play => {
          if (topTeam[play.team_id]) {
            topTeam[play.team_id]['total'] = topTeam[play.team_id]['total'] + play.total;
          } else {
            topTeam[play.team_id] = {};
            topTeam[play.team_id]['total'] = play.total;
            topTeam[play.team_id]['id'] = this.teamInfo[play.team_id].id;
            topTeam[play.team_id]['name'] = this.teamInfo[play.team_id].name;
            topTeam[play.team_id]['img'] = 'https://az755631.vo.msecnd.net/teams-80/' + play.team_id + '.png';
          }
          if (topPlayer[play.player_id]) {
            if (play.player_id != 0 && play.player_id != 62117) {
              topPlayer[play.player_id]['total'] = topPlayer[play.player_id]['total'] + play.total;
            }
          } else {
            if (play.player_id != 0 && play.player_id != 62117) {
              topPlayer[play.player_id] = {};
              topPlayer[play.player_id]['total'] = play.total;
              if (play.player_id) {
                topPlayer[play.player_id]['name'] = this.playersInfo[play.player_id].name;
                topPlayer[play.player_id]['img'] = this.playersImagesUrl + play.player_id + '.png';
                topPlayer[play.player_id]['id'] = this.playersInfo[play.player_id].id;
              } else {
                topPlayer[play.player_id]['id'] = '0';
                topPlayer[play.player_id]['name'] = '-';
                topPlayer[play.player_id]['img'] = 'SIN IMAGEN';
              }
            }
          }
        });
        for (const idTeam in topTeam) {
          sortTopTeam.push(topTeam[idTeam]);
        }
        for (const idPlayer in topPlayer) {
          sortTopPlayer.push(topPlayer[idPlayer]);
        }
        sortTopTeam.sort((a, b) => (a.total < b.total ? 1 : -1));
        sortTopPlayer.sort((a, b) => (a.total < b.total ? 1 : -1));
        sortTopTeam = sortTopTeam.slice(0, 60);
        sortTopPlayer = sortTopPlayer.slice(0, 60);
      }

      this.updateSliderF();
      if (this.isSwitched) {
        return this.formatArrayPaginate(sortTopTeam);
      } else {
        return this.formatArrayPaginate(sortTopPlayer);
      }
    },
  },
  watch: {
    displayWidth: {
      immediate: true,
      handler() {
        if (this.arrayPredefinedFilters.length) {
          this.getTextFilterPredefinedCustom();
        }
      },
    },
    isSwitched() {
      if (this.isSwitched) {
        this.typeSelected = 0;
      } else {
        this.typeSelected = 1;
      }
      this.getTextFilterPredefinedCustom();
      this.viewImage = 0;
      this.updateSliderF();
    },
    logo: {
      immediate: true,
      handler: function(newValue) {
        this.customValues.logo = newValue;
      },
    },
    interiors: {
      immediate: true,
      handler: function(newValue) {
        this.customValues.bgColor = newValue.background;
        this.customValues.color = newValue.color;
      },
    },
    header: {
      immediate: true,
      handler: function(newValue) {
        this.customValuesHeaderBackground.background = newValue.background;
        this.customValuesHeaderBackground.color = newValue.color;
        this.customValuesHeaderBackground.borderColor = newValue.borderColor;
        this.customValuesHeader2Background.borderColor = newValue.borderColor;
      },
    },
    header2: {
      immediate: true,
      handler: function(newValue) {
        this.customValuesHeader2Background.background = newValue.background;
        this.customValuesHeader2Background.color = newValue.color;
      },
    },
    isLoggedIn: {
      immediate: true,
      handler: async function(newValue) {
        if (newValue && !this.isMenuHidden) {
          await this.getCategoryStats(this.season);
          await this.getPredefinedfilters(this.category);
        }
      },
    },
    season: {
      immediate: false,
      handler(newValue) {
        if (!newValue) return;
        const wasMenuHidden = this.isMenuHidden;
        this.topsTeams = {};
        this.topsPlayers = {};
        this.playersInfo = {};
        this.teamInfo = {};
        this.statsData = {};
        this.formatFilterStats = [];
        this.isMenuHidden = true;
        if (!wasMenuHidden) {
          this.intersectionObserverCounter = 0;
          this.isVisible = false;
          setTimeout(() => {
            this.setUpIntersectionObserver();
          }, 500);
        } else if (this.isVisible) {
          this.isMenuHidden = false;
        }
      },
    },
    async isMenuHidden(newValue) {
      if (!newValue) {
        await this.getCategoryStats(this.season);
        await this.getPredefinedfilters(this.category);
      }
    },
  },
  methods: {
    changeCarousel(index) {
      this.indexPredefinedFiltersSelected = index;
      this.arrayPredefinedFilters[index].filtersPredefined = JSON.parse(
        JSON.stringify(this.arrayPredefinedOrigin[index].filtersPredefined),
      );
      this.filters(this.arrayPredefinedOrigin[index].filtersPredefined);
    },
    changeItem(value) {
      this.viewImage = value;
    },
    async filters(filters) {
      this.getTextFilterPredefinedCustom();
      this.formatFilterStats = await this.filterRecords(filters);
      this.filterActive = true;
      this.viewImage = 0;
    },
    getTextFilterPredefinedCustom() {
      var filterTextCustom = this.arrayPredefinedFilters[this.indexPredefinedFiltersSelected].textOrigin;
      const filterFieldSearch = this.arrayPredefinedFilters[this.indexPredefinedFiltersSelected].filtersPredefined.zone
        .sort((a, b) => a - b)
        .toString();
      if (
        this.predefinedFiltersSections[filterFieldSearch] ||
        this.predefinedFiltersSections[filterFieldSearch] == ''
      ) {
        if (this.predefinedFiltersSections[filterFieldSearch] == '') {
          filterTextCustom = filterTextCustom.replace(
            ' [en {field_sections}]',
            this.predefinedFiltersSections[filterFieldSearch],
          );
        } else {
          filterTextCustom = filterTextCustom.replace(
            '{field_sections}',
            this.predefinedFiltersSections[filterFieldSearch],
          );
        }
      } else {
        filterTextCustom = filterTextCustom.replace('{field_sections}', this.predefinedFiltersSections['other']);
      }
      const filterMatchSearch = this.arrayPredefinedFilters[
        this.indexPredefinedFiltersSelected
      ].filtersPredefined.sections
        .sort()
        .toString();
      if (
        this.predefinedFiltersMatchLapse[filterMatchSearch] ||
        this.predefinedFiltersMatchLapse[filterMatchSearch] == ''
      ) {
        if (this.predefinedFiltersMatchLapse[filterMatchSearch] == '') {
          filterTextCustom = filterTextCustom.replace(
            ' [durante {match_lapse}]',
            this.predefinedFiltersMatchLapse[filterMatchSearch],
          );
        } else {
          filterTextCustom = filterTextCustom.replace(
            '{match_lapse}',
            this.predefinedFiltersMatchLapse[filterMatchSearch],
          );
        }
      } else {
        filterTextCustom = filterTextCustom.replace('{match_lapse}', ' ' + this.predefinedFiltersMatchLapse['other']);
        var textfilterMatchAlternative = '';
        if (filterMatchSearch.includes(1)) {
          textfilterMatchAlternative = textfilterMatchAlternative + '0-15, ';
        }
        if (filterMatchSearch.includes(2)) {
          textfilterMatchAlternative = textfilterMatchAlternative + '16-30, ';
        }
        if (filterMatchSearch.includes(3)) {
          textfilterMatchAlternative = textfilterMatchAlternative + '31-45, ';
        }
        if (filterMatchSearch.includes(4)) {
          textfilterMatchAlternative = textfilterMatchAlternative + '46-60, ';
        }
        if (filterMatchSearch.includes(5)) {
          textfilterMatchAlternative = textfilterMatchAlternative + '61-75, ';
        }
        if (filterMatchSearch.includes(6)) {
          textfilterMatchAlternative = textfilterMatchAlternative + '76-90, ';
        }
        textfilterMatchAlternative = textfilterMatchAlternative.slice(0, -2);
        textfilterMatchAlternative = textfilterMatchAlternative.replace(/,([^,]*)$/, ' y ' + '$1');
        filterTextCustom = filterTextCustom.replace('{match_lapse_selected}', textfilterMatchAlternative);
      }
      const filterPlayingAsSearch = this.arrayPredefinedFilters[
        this.indexPredefinedFiltersSelected
      ].filtersPredefined.playingAs
        .sort()
        .toString();
      if (
        this.predefinedFiltersPlayingAs[filterPlayingAsSearch] ||
        this.predefinedFiltersPlayingAs[filterPlayingAsSearch] == ''
      ) {
        if (this.predefinedFiltersPlayingAs[filterPlayingAsSearch] == '') {
          filterTextCustom = filterTextCustom.replace(
            ' [jugando como {playing_as}]',
            this.predefinedFiltersPlayingAs[filterPlayingAsSearch],
          );
        } else {
          filterTextCustom = filterTextCustom.replace(
            '{playing_as}',
            this.predefinedFiltersPlayingAs[filterPlayingAsSearch],
          );
        }
      }
      if (this.typeSelected == 1) {
        if (this.tournamentSelected.id == 1001) {
          if (this.category.id == 22) {
            filterTextCustom = filterTextCustom.replace('{section_type}', 'Porteras');
          } else {
            filterTextCustom = filterTextCustom.replace('{section_type}', 'Jugadoras');
          }
        } else {
          if (this.category.id == 22) {
            filterTextCustom = filterTextCustom.replace('{section_type}', 'Porteros');
          } else {
            filterTextCustom = filterTextCustom.replace('{section_type}', 'Jugadores');
          }
        }
      } else {
        filterTextCustom = filterTextCustom.replace('{section_type}', 'Equipos');
      }
      filterTextCustom = filterTextCustom.replace(/\[/g, '');
      filterTextCustom = filterTextCustom.replace(/\]/g, '');
      filterTextCustom = filterTextCustom.replace(
        '{category_name}',
        this.arrayPredefinedFilters[this.indexPredefinedFiltersSelected].category_name,
      );
      const filterNameItem = filterTextCustom.split(': ');
      var textFinal = filterNameItem[1].charAt(0).toUpperCase() + filterNameItem[1].toLowerCase().slice(1);
      var textForDots = filterNameItem[1].charAt(0).toUpperCase() + filterNameItem[1].toLowerCase().slice(1);
      this.arrayPredefinedFilters[this.indexPredefinedFiltersSelected].text = textFinal;
      this.arrayPredefinedFilters[this.indexPredefinedFiltersSelected].title = filterNameItem[0] + ':';

      const matches = textForDots.match(/\*/g);
      if (matches && matches.length) {
        if (matches.length % 2 === 0) {
          for (let i = 1; i <= matches.length; i++) {
            if (i % 2 === 0) {
              // textForDots = textForDots.replace('*', '</span>');
            } else {
              textForDots = textForDots.replace('*', '<span style="color: #ffff03" >');
            }
          }
        } else {
          textForDots = textForDots.replaceAll('*', '');
        }
      }
      var dotshtmladd =
        '<span ' +
        "id='showIDtootip' " +
        "class='dots' " +
        "style='width: 100px; height: 20px; z-index: 6; cursor:pointer;'>" +
        '...</span></span>';
      if (this.displayWidth >= 1220) {
        if (textForDots.length > 139) {
          textForDots = textForDots.substring(0, 139) + dotshtmladd;
        }
      }

      if (this.displayWidth >= 1150 && this.displayWidth < 1220) {
        if (textForDots.length > 130) {
          textForDots = textForDots.substring(0, 130) + dotshtmladd;
        }
      }

      if (this.displayWidth > 1080 && this.displayWidth < 1150) {
        if (textForDots.length > 124) {
          textForDots = textForDots.substring(0, 124) + dotshtmladd;
        }
      }
      if (this.displayWidth > 700 && this.displayWidth < 750) {
        if (textForDots.length > 172) {
          textForDots = textForDots.substring(0, 172) + dotshtmladd;
        }
      }
      if (this.displayWidth > 650 && this.displayWidth <= 700) {
        if (textForDots.length > 159) {
          textForDots = textForDots.substring(0, 159) + dotshtmladd;
        }
      }
      if (this.displayWidth > 600 && this.displayWidth <= 650) {
        if (textForDots.length > 139) {
          textForDots = textForDots.substring(0, 139) + dotshtmladd;
        }
      }
      if (this.displayWidth > 550 && this.displayWidth <= 600) {
        if (textForDots.length > 130) {
          textForDots = textForDots.substring(0, 130) + dotshtmladd;
        }
      }
      if (this.displayWidth > 500 && this.displayWidth <= 550) {
        if (textForDots.length > 118) {
          textForDots = textForDots.substring(0, 118) + dotshtmladd;
        }
      }
      if (this.displayWidth > 390 && this.displayWidth <= 500) {
        if (textForDots.length > 95) {
          textForDots = textForDots.substring(0, 95) + dotshtmladd;
        }
      }
      if (this.displayWidth > 360 && this.displayWidth <= 390) {
        if (textForDots.length > 85) {
          textForDots = textForDots.substring(0, 85) + dotshtmladd;
        }
      }
      if (this.displayWidth > 340 && this.displayWidth <= 360) {
        if (textForDots.length > 100) {
          textForDots = textForDots.substring(0, 100) + dotshtmladd;
        }
      }
      if (this.displayWidth <= 340) {
        if (textForDots.length > 90) {
          textForDots = textForDots.substring(0, 90) + dotshtmladd;
        }
      }
      this.arrayPredefinedFilters[this.indexPredefinedFiltersSelected].titledots = textForDots;
    },
    async getPredefinedfilters(category) {
      this.arrayPredefinedFilters = [];
      this.arrayPredefinedOrigin = [];
      const dataCategory = await axios
        .get(this.urlFilter + this.category.id)
        .then(response => response)
        .catch(e => {
          if (this.retries > 0) {
            this.retries = this.retries - 1;
            return this.getPredefinedfilters(category);
          }
          return e;
        });
      Object.keys(dataCategory.data[0].match_lapse_combinations).forEach(key => {
        this.predefinedFiltersMatchLapse[key.toString()] = dataCategory.data[0].match_lapse_combinations[key];
      });
      Object.keys(dataCategory.data[0].playing_as_combinations).forEach(key => {
        this.predefinedFiltersPlayingAs[key.toString()] = dataCategory.data[0].playing_as_combinations[key];
      });
      Object.keys(dataCategory.data[0].field_sections_combinations).forEach(key => {
        this.predefinedFiltersSections[key.toString()] = dataCategory.data[0].field_sections_combinations[key];
      });
      if (dataCategory.data[0]) {
        for (let indexRecord = 0; indexRecord < dataCategory.data[0].filter_records.length; indexRecord++) {
          var filterText = dataCategory.data[0].description;

          if (dataCategory.data[0].filter_records[indexRecord].field_sections !== undefined) {
            const filterFieldSearch = dataCategory.data[0].filter_records[indexRecord].field_sections.toString();
            if (
              this.predefinedFiltersSections[filterFieldSearch] ||
              this.predefinedFiltersSections[filterFieldSearch] == ''
            ) {
              if (this.predefinedFiltersSections[filterFieldSearch] == '') {
                filterText = filterText.replace(
                  ' [en {field_sections}]',
                  this.predefinedFiltersSections[filterFieldSearch],
                );
              } else {
                filterText = filterText.replace('{field_sections}', this.predefinedFiltersSections[filterFieldSearch]);
              }
            }
          }
          if (dataCategory.data[0].filter_records[indexRecord].match_lapse !== undefined) {
            const filterMatchSearch = dataCategory.data[0].filter_records[indexRecord].match_lapse.toString();
            if (
              this.predefinedFiltersMatchLapse[filterMatchSearch] ||
              this.predefinedFiltersMatchLapse[filterMatchSearch] == ''
            ) {
              if (this.predefinedFiltersMatchLapse[filterMatchSearch] == '') {
                filterText = filterText.replace(
                  ' [durante {match_lapse}]',
                  this.predefinedFiltersMatchLapse[filterMatchSearch],
                );
              } else {
                filterText = filterText.replace('{match_lapse}', this.predefinedFiltersMatchLapse[filterMatchSearch]);
              }
            }
          }
          if (dataCategory.data[0].filter_records[indexRecord].playing_as !== undefined) {
            const filterPlayingAsSearch = dataCategory.data[0].filter_records[indexRecord].playing_as.toString();
            if (
              this.predefinedFiltersPlayingAs[filterPlayingAsSearch] ||
              this.predefinedFiltersPlayingAs[filterPlayingAsSearch] == ''
            ) {
              if (this.predefinedFiltersPlayingAs[filterPlayingAsSearch] == '') {
                filterText = filterText.replace(
                  ' [jugando como {playing_as}]',
                  this.predefinedFiltersPlayingAs[filterPlayingAsSearch],
                );
              } else {
                filterText = filterText.replace('{playing_as}', this.predefinedFiltersPlayingAs[filterPlayingAsSearch]);
              }
            }
          }
          if (dataCategory.data[0].filter_records[indexRecord].section_type !== undefined) {
            if (dataCategory.data[0].filter_records[indexRecord].section_type == 1) {
              filterText = filterText.replace('{section_type}', 'Jugadores');
            } else {
              filterText = filterText.replace('{section_type}', 'Equipos');
            }
          }

          filterText = filterText.replace(/\[/g, '');
          filterText = filterText.replace(/\]/g, '');
          filterText = filterText.replace('{category_name}', dataCategory.data[0].category_name);
          const filterNameItem = filterText.split(': ');
          var textFinal = filterNameItem[1];
          if (textFinal.length > 129 && this.displayWidth > 1358) {
            textFinal = textFinal.substring(0, 129);
            textFinal =
              textFinal +
              "<span id='showIDtootip' class='dots' style='width: 100px; height: 20px; background: red; z-index: 6;'" +
              '>...</span>';
          }
          if (!this.isCup || dataCategory.data[0].filter_records[indexRecord].playing_as.length === 2) {
            this.arrayPredefinedFilters.push({
              id: indexRecord,
              category_name: this.category.name,
              id_category: dataCategory.data[0].id,
              title: filterNameItem[0] + ':',
              text: filterNameItem[1],
              textOrigin: dataCategory.data[0].description,
              titledots: textFinal,
              filtersPredefined: {
                matchResult: dataCategory.data[0].filter_records[indexRecord].match_result,
                playingAs: dataCategory.data[0].filter_records[indexRecord].playing_as,
                sections: dataCategory.data[0].filter_records[indexRecord].match_lapse,
                type: 1,
                typeZone: dataCategory.data[0].type_zone,
                zone: dataCategory.data[0].filter_records[indexRecord].field_sections,
              },
            });
          }
        }
        this.filters(this.arrayPredefinedFilters[0].filtersPredefined);
        this.arrayPredefinedOrigin = JSON.parse(JSON.stringify(this.arrayPredefinedFilters));
      }
    },

    async getCategoryStats(season) {
      try {
        if (!season) return;
        let catPlays = [];
        let teamData = {};
        let playersData = {};
        this.arePlaysEmpty = false;
        var categoryget = '';
        if (this.category.id == 1 || this.category.id == 2) {
          categoryget = this.category.id + '?p=1';
        } else {
          categoryget = this.category.id;
        }

        const data = await axios
          .get(this.url + '/' + season + '/' + categoryget)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.getCategoryStats(season);
            }
            return e;
          });
        if (data && data.data && data.data.data && data.data.data.length > 0) {
          catPlays = data.data.data.map(play => {
            return this.formatStatsData(play);
          });
          data.data.teams.map(data => {
            teamData[data[0]] = {};
            teamData[data[0]]['id'] = data[0];
            teamData[data[0]]['name'] = data[1];
            teamData[data[0]]['img'] = data[3];
            teamData[data[0]]['abrv'] = data[2];
          });
          data.data.players.map(data => {
            playersData[data[0]] = {};
            playersData[data[0]]['id'] = data[0];
            playersData[data[0]]['name'] = data[1];
            playersData[data[0]]['img'] = data[2];
          });
        } else {
          this.arePlaysEmpty = true;
        }
        this.teamInfo = teamData;
        this.playersInfo = playersData;
        this.formatStats = catPlays;
        this.formatFilterStats = this.filterRecords(this.filtersSelect);
        this.statsData = data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    updateSliderF() {
      this.updateSlider += 1;
    },
    filterRecords(filtersEmit) {
      var allFilters = false;
      var statsFilter = [];
      statsFilter = this.formatStats.filter(play => {
        var matchZone = false;
        const playingAs = filtersEmit.playingAs.includes(play.playing_as);
        const matchResult = filtersEmit.matchResult.includes(play.match_result);
        const matchLapse = filtersEmit.sections.includes(play.section);

        if (filtersEmit.typeZone == 1) {
          matchZone = filtersEmit.zone.includes(play.origen);
        }
        if (filtersEmit.typeZone == 2) {
          matchZone = filtersEmit.zone.includes(play.destino);
        }
        if (
          filtersEmit.playingAs.length > 0 ||
          filtersEmit.matchResult.length > 0 ||
          filtersEmit.sections.length > 0 ||
          filtersEmit.zone.length > 0
        ) {
          if (
            filtersEmit.playingAs.length > 0 &&
            filtersEmit.matchResult.length > 0 &&
            filtersEmit.sections.length > 0 &&
            filtersEmit.zone.length > 0
          ) {
            allFilters = playingAs && matchResult && matchLapse && matchZone;
          } else {
            if (
              filtersEmit.playingAs.length > 0 &&
              filtersEmit.matchResult.length > 0 &&
              filtersEmit.sections.length > 0
            ) {
              allFilters = playingAs && matchResult && matchLapse;
            } else {
              if (
                filtersEmit.playingAs.length > 0 &&
                filtersEmit.matchResult.length > 0 &&
                filtersEmit.zone.length > 0
              ) {
                allFilters = playingAs && matchResult && matchZone;
              } else {
                if (
                  filtersEmit.matchResult.length > 0 &&
                  filtersEmit.sections.length > 0 &&
                  filtersEmit.zone.length > 0
                ) {
                  allFilters = matchResult && matchLapse && matchZone;
                } else {
                  if (filtersEmit.playingAs.length > 0 && filtersEmit.zone.length > 0) {
                    allFilters = playingAs && matchZone;
                  } else {
                    if (filtersEmit.playingAs.length > 0 && filtersEmit.sections.length > 0) {
                      allFilters = playingAs && matchLapse;
                    } else {
                      if (filtersEmit.playingAs.length > 0 && filtersEmit.matchResult.length > 0) {
                        allFilters = playingAs && matchResult;
                      } else {
                        if (filtersEmit.zone.length > 0 && filtersEmit.sections.length > 0) {
                          allFilters = matchZone && matchLapse;
                        } else {
                          if (filtersEmit.zone.length > 0 && filtersEmit.matchResult.length > 0) {
                            allFilters = matchZone && matchResult;
                          } else {
                            if (filtersEmit.sections.length > 0 && filtersEmit.matchResult.length > 0) {
                              allFilters = matchLapse && matchResult;
                            } else {
                              allFilters = matchResult || matchLapse || playingAs || matchZone;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          allFilters = true;
        }
        this.updateSliderF();
        return allFilters;
      });

      return statsFilter;
    },
    formatArrayPaginate(arrayData) {
      let num = parseInt(arrayData.length / 10);
      let mod = arrayData.length % 10;
      if (mod > 0) {
        num = num + 1;
      }
      var arrayPlayers = [];
      for (let index = 1; index <= num; index++) {
        var items = index * 10;
        arrayPlayers[index - 1] = arrayData.slice(items - 10, items);
      }
      return arrayPlayers;
    },
    formatStatsData(play) {
      let formattedPlay = {};
      if (play[1] == 0 && play[2] == 1) {
        play[2] = 2;
      } else {
        if (play[1] == 0 && play[2] == 2) {
          play[2] = 1;
        }
      }

      if (play[1] == 0 && play[3] == 1) {
        play[3] = 2;
      } else {
        if (play[1] == 0 && play[3] == 2) {
          play[3] = 1;
        }
      }

      this.data_headers.forEach((header, index) => {
        formattedPlay[header] = play[index];
      });
      return formattedPlay;
    },
    show(flag) {
      if (flag) {
        this.openLayer = true;
        setTimeout(() => {
          this.openDrawer = true;
        }, 100);
      } else {
        this.openDrawer = false;
        setTimeout(() => {
          this.openLayer = false;
        }, 500);
      }
    },
    showDrawer() {
      this.openLayer = true;
      setTimeout(() => {
        this.openDrawer = true;
      }, 100);
    },
    hideDrawer() {
      this.openDrawer = false;
      setTimeout(() => {
        this.openLayer = false;
      }, 500);
    },
    setUpIntersectionObserver() {
      if (!window.IntersectionObserver) {
        this.isMenuHidden = false;
        return;
      }
      const observer = new IntersectionObserver(entries => {
        this.intersectionObserverCounter++;
        if (entries[0].intersectionRatio > 0 || this.intersectionObserverCounter === 2) {
          observer.unobserve(this.$refs.skeletonBlock.$el);
          if (this.season) {
            this.isMenuHidden = false;
          } else {
            this.isVisible = true;
          }
        }
      });
      observer.observe(this.$refs.skeletonBlock.$el);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setUpIntersectionObserver();
    });
  },
};
</script>
<style lang="scss" scoped>
.text-bold-overlay {
  font-size: 27px;
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
}
.subtext-bold-overlay {
  font-size: 19px;
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
}
.container-menu-top-all {
  overflow: hidden;
}
.div-centyer-text {
  width: 100%;
  margin-top: 44%;
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
  position: relative;
}
$blur: 10px;
.blurred {
  filter: blur($blur);
  -webkit-filter: blur($blur);
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 0;
}
.table-overlay-container {
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 450px;
  overflow: visible;
  position: absolute;
  @media screen and (max-width: 500px) {
    height: 430px;
  }
}
@mixin table-overlay {
  width: 94%;
  height: 94%;
  margin: 0 auto;
  margin-top: 10px;
  font-family: 'Circular-Std-Medium';
  border: dotted 2px #5c5c5c;
  color: #3e3e3e;
  border-radius: 8px;
  display: table;
  text-align: center;
  position: relative;
}

@mixin font-circular-bold {
  font-family: 'Circular-Std-Black';
  color: #272727f5;
}

.table-overlay {
  &--mobile {
    @include table-overlay;
    // font-size: clamp(0.5rem, 1.5rem, 3vw);
    & > b {
      @include font-circular-bold;
    }
  }
  &--desktop {
    @include table-overlay;
    @media screen and (min-width: 1280px) {
      // font-size: clamp(0.5rem, 1.5rem, 1.5vw);
      & > b {
        @include font-circular-bold;
      }
    }
  }

  &--mobile,
  &--desktop {
    transition: width 50ms;
    transition-timing-function: linear;
  }
}
.border-detail-top {
  border-bottom: 2px solid #88da69;
}
.border-detail-bottom {
  @media screen and (max-width: 500px) {
    border-top: 2px solid #88da69;
  }
}
.container-not-found-data {
  width: 100%;
  border-radius: 5px;
  height: 370px;
  border: 2px dashed #dadada;
  display: inline-block;
  &__img-search {
    width: 100%;
    margin-top: 90px;
  }
  &__title-info {
    width: 100%;
    margin-top: 14px;
    text-align: center;
    font-family: 'Circular-Std';
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.52px;
  }
  &__subtitle-info {
    width: 100%;
    text-align: center;
    margin-top: 4px;
    font-family: 'Avenir-Roman';
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.12px;
    color: #9a9a9a;
  }
}
.close-button {
  cursor: pointer;
  position: absolute;
  font-size: 24px;
  letter-spacing: -0.04px;
  text-align: center;
  background-color: #494a4b;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 1.6em;
  padding-top: 8px;
  display: none;
  margin-left: 23px;
  margin-top: 5px;
  border-radius: 4px;
}
.color-gray {
  background-color: #3b3b3b !important;
}
.menu-top {
  width: 100%;
  //margin: 0px !important;
  margin: 10px;
  display: inline-block;
  position: relative;
  border-radius: 2px;
  border: solid 1.1px #c0c0c0;
  height: fit-content;
  &__header {
    border-radius: 2px;
    background-color: #242424;
    padding: 7px 0px 0px 0px;
    position: relative;
    display: flex;
    align-items: center;
    height: 34px;
    @media screen and (max-width: 500px) {
      flex-flow: column;
      height: unset;
    }
  }
  &__header-pc {
    background-color: #242424;
    padding: 7px 0px;
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
  }
  &__body {
    display: grid;
    // background: pink;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 9px;
    padding: 0px 9px 9px 9px;

    @media screen and (max-width: 500px) {
      grid-template-columns: 1fr;
      padding: 0px 0px 12px 0px;
    }
  }
  &__drawer {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    justify-content: flex-end;
    display: none;
    height: 425px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    margin: 262px 0px;
    padding: 0px;
    @media screen and (max-width: 500px) {
      height: 455px;
    }
    @media screen and (max-width: 370px) {
      margin: 259px 0px;
    }

    &.active {
      @media screen and (max-width: 500px) {
        display: flex;
      }
    }
    .filter-drawer {
      background-color: white;
      width: 0px;
      overflow: hidden;
      transition: all 0.5s ease-in-out;
      opacity: 0;
      position: relative;
      &.active {
        width: 100%;
        opacity: 1;
        height: 455px;
      }

      .close-btn {
        position: absolute;
        top: 10px;
        left: 21px;
        width: 25px;
        height: 25px;
        color: white;
        display: flex;
        justify-content: center;
        border-radius: 4px;
        background-color: #3b3b3b;
        align-items: center;
        font-size: 21px;
        cursor: pointer;
        line-height: 19px;
        outline: none;
        border: none;
      }
    }
  }
  &__footer {
    border-radius: 2px;
    min-height: 40px;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.07);
    background-color: #fbfbfb;
    display: flex;
    justify-content: center;
    align-items: center;
    .spacer {
      width: 1px;
      height: 0.75rem;
      background-color: #979797;
      margin: 0 16px;
    }
  }
}
.top-header-pc {
  .logo-img {
    position: absolute;
    left: 11px;
    width: 28px;
  }
  &__left-items {
    margin-right: auto;
  }
  &__title {
    font-family: 'Circular-Std-Medium';
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.52px;
    text-align: center;
    color: #ffffff;
    padding-left: 0px;
    position: absolute;
    right: 0;
    left: 0;
    @media screen and (max-width: 560px) {
      font-size: 18px;
    }
  }

  &__right-items {
    font-family: 'Circular-Std-Medium';
    font-size: 11px;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    padding-top: 1px;
    color: #ffffff;

    span {
      padding-right: 10px;
      font-size: 12px;
      color: white;
      opacity: 0.7;

      &.active {
        opacity: 1;
        font-weight: bold;
      }
    }
  }
  @media screen and (max-width: 500px) {
    display: none;
  }
}
.top-header {
  display: none;
  .logo-img {
    position: absolute;
    left: 15px;
    width: 18px;
    @media screen and (max-width: 500px) {
      top: 3px;
    }
  }
  &__left-items {
    margin-right: auto;
  }
  &__adv-btn {
    display: none;
    @media screen and (max-width: 500px) {
      outline: none;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 220px;
      height: 25px;
      margin: 12px 0px;
      border-radius: 7px;
      background-color: #6abe0f;
      font-family: 'Circular-Std-Medium';
      font-size: 12px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
    }
    img {
      margin-left: 5px;
      width: 12px;
    }
  }
  &__title {
    font-family: 'Circular-Std-Medium';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.52px;
    text-align: center;
    color: #ffffff;
    padding-left: 10px;
    position: absolute;
    right: 0;
    left: 0;

    @media screen and (max-width: 500px) {
      position: initial;
    }
    @media screen and (max-width: 370px) {
      font-size: 12px;
      height: 16px;
    }
  }
  &__right-items {
    font-family: 'Circular-Std-Medium';
    font-size: 11px;
    font-weight: 500;
    width: 100%;
    height: 30px;
    background: #3b4044;
    padding-top: 5px;
    text-align: center;
    padding-top: 1px;
    color: #ffffff;

    span {
      padding-right: 10px;
    }

    &__background-cont {
      font-family: 'Circular-Std-Medium';
      font-size: 11px;
      font-weight: 500;
      text-align: center;
      display: inline-block;
      padding-top: 5px;
      color: #ffffff;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 500px) {
    display: flex;
    background-color: blue;
  }
}
.top-body {
  min-height: 452px;
  &__table {
    // background: brown;
  }
  &__filters {
    padding: 4px 0px;
    width: 100%;
    display: flex;
    // background: red;

    @media screen and (max-width: 500px) {
      display: none;
    }
  }
}
.placeholder-item:nth-of-type(odd) {
  background-color: #f5f5f5;
  box-shadow: 0 1px 2px 0 rgba(33, 33, 33, 0.05);
}
.placeholder-item {
  height: 32px;
  position: relative;
  overflow: hidden;
}
.placeholder-item:nth-of-type(odd)::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
.top-ptable {
  width: 100%;
  margin-bottom: 10px;
  &__header {
    display: grid;
    grid-template-columns: minmax(25px, 30px) 1fr minmax(40px, 60px);
    border-radius: 2.4px;
    text-align: center;
    align-items: center;
  }
  &__header {
    background-color: #dbefca;
    height: 24px;
    span {
      font-family: 'Avenir-Pro-Bold';
      font-size: 12px;
      font-weight: bold;
      letter-spacing: -0.18px;
      text-align: center;
      color: #353535;
    }
  }
}
.container-filter-switch {
  span {
    padding-right: 10px;
    font-size: 12px;
    color: white;
    opacity: 0.7;

    &.active {
      opacity: 1;
      font-weight: bold;
    }
  }
}
</style>
<style lang="scss">
$green: #cbee6b;
.menu-top {
  .switch input[type='checkbox'] + .check.is-success-passive,
  .switch input[type='checkbox'] + .check:hover {
    background: $green;
  }

  .b-radio.radio input[type='radio']:checked + .check.is-success {
    background: $green !important;
    border-color: #95dc28 !important;
  }
  .b-radio.radio input[type='radio'] + .check.is-success:before {
    background: $green !important;
  }
  .checkbox input[type='checkbox']:checked + .check.is-success {
    border-color: $green !important;
    background: $green
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:white' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat center center !important;
  }
  .top-body__table .carousel .carousel-indicator .indicator-item .indicator-style {
    background-color: #e0e0e0;
    border: none;
  }
  .top-body__table .carousel .carousel-indicator .indicator-item.is-active .indicator-style,
  .carousel .carousel-indicator .indicator-item .indicator-style:hover {
    background: $green;
  }
}
</style>
