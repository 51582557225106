<template>
  <div class="yourewatching">
    <div class="filterButton" @click="showDrawer">
      <img src="/images/icn-filter.png" />
    </div>
    <div class="container-slide-selected" v-if="showmenu">
      <div class="yourewatching__item-carousel">
        <div class="yourewatching__item-carousel__title">{{ filters[selected].title }}</div>
        <div
          class="yourewatching__item-carousel__text"
          @click="clicktooltip"
          v-html="getFormattedBullet(filters[selected].titledots)"
        ></div>
        <div class="btnViewPlayersOrTeam" @click="showDrawerClose">
          <template v-if="type == 1"
            ><template v-if="tournamentSelected.id == 1001">
              <template v-if="category == 22">Ver porteras</template>
              <template v-else>Ver jugadoras</template>
            </template>
            <template v-else>
              <template v-if="category == 22">Ver porteros</template>
              <template v-else>Ver jugadores</template>
            </template></template
          ><template v-else>Ver equipos</template>
        </div>
      </div>
    </div>
    <div class="container-slider">
      <BCarousel
        v-model="carouselIndex"
        :interval="3000"
        :autoplay="false"
        :pause-info="false"
        @change="getChangeCarousel"
      >
        <template v-for="(filter, key) in filters">
          <BCarouselItem :key="key">
            <div class="yourewatching__item-carousel">
              <div class="yourewatching__item-carousel__title">{{ filter.title }}</div>
              <div
                class="yourewatching__item-carousel__text"
                v-html="getFormattedBullet(filter.titledots)"
                @click="handleLineClick"
                @mouseover="handleLineMouseOver"
                @mouseout="handleLineMouseOut"
              ></div>

              <div class="yourewatching__item-carousel__indicator">
                (Desliza para ver siguiente)<img src="/images/icn-back.png" width="22" @click="onArrowClick" />
              </div>
            </div>
          </BCarouselItem>
        </template>
      </BCarousel>
    </div>
    <div class="tooltip-topscategory" v-if="tooltip" @click="closeTooltip">
      <div class="tooltip-topscategory__container">
        <span class="tooltip-topscategory__container__bold">{{ filters[selected].title }}</span> <br /><br />
        <div v-html="getFormattedBulletTooltip(filters[selected].text)"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';
export default {
  name: 'YoureWatching',
  components: { BCarousel, BCarouselItem },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Number,
      require: true,
    },
    category: {
      type: Number,
      require: true,
    },
    type: {
      type: Number,
      require: true,
    },
  },
  watch: {
    displayWidth: {
      immediate: true,
      handler(newValue) {
        if (newValue > 500) {
          this.tooltip = false;
        }
      },
    },
  },
  data: () => ({
    carouselIndex: 0,
    showmenu: false,
    tooltip: false,
  }),
  computed: {
    ...mapState('general', ['tournamentSelected']),
    ...mapState({ displayWidth: 'displayWidth' }),
  },
  methods: {
    handleLineMouseOut(e) {
      if (e.target.id === 'showIDtootip') {
        if (this.displayWidth > 500) {
          setTimeout(() => {
            this.tooltip = false;
          }, 500);
        }
      }
    },
    handleLineMouseOver(e) {
      if (e.target.id === 'showIDtootip') {
        if (this.displayWidth > 500) {
          this.tooltip = true;
        }
      }
    },
    handleLineClick(e) {
      if (e.target.id === 'showIDtootip') {
        if (this.displayWidth <= 500) {
          this.tooltip = true;
        }
      }
    },
    mouseOver() {
      if (this.displayWidth > 500) {
        this.tooltip = true;
      }
    },
    hoverOut() {
      if (this.displayWidth > 500) {
        setTimeout(() => {
          this.tooltip = false;
        }, 500);
      }
    },
    clicktooltip() {
      if (this.displayWidth <= 500) {
        this.tooltip = true;
      }
    },
    closeTooltip() {
      if (this.displayWidth <= 500) {
        this.tooltip = false;
      }
    },
    getFormattedBullet(bullet) {
      let formattedBullet = bullet;
      const matches = bullet.match(/\*/g);
      if (matches && matches.length) {
        if (matches.length % 2 === 0) {
          for (let i = 1; i <= matches.length; i++) {
            if (i % 2 === 0) {
              formattedBullet = formattedBullet.replace('*', '</span>');
            } else {
              formattedBullet = formattedBullet.replace('*', '<span style="color: #ffff03" >');
            }
          }
        } else {
          formattedBullet = bullet.replaceAll('*', '');
        }
      }

      return formattedBullet;
    },
    getFormattedBulletTooltip(bullet) {
      let formattedBullet = bullet;
      const matches = bullet.match(/\*/g);
      if (matches && matches.length) {
        if (matches.length % 2 === 0) {
          for (let i = 1; i <= matches.length; i++) {
            if (i % 2 === 0) {
              formattedBullet = formattedBullet.replace('*', '</span>');
            } else {
              formattedBullet = formattedBullet.replace('*', '<span style="color: #fff" >');
            }
          }
        } else {
          formattedBullet = bullet.replaceAll('*', '');
        }
      }
      return `<span>${formattedBullet}</span>`;
    },
    getChangeCarousel(value) {
      this.carouselIndex = value;
      this.$emit('changeCarousel', value);
    },
    showDrawer() {
      this.showmenu = true;
      this.$emit('show', this.showmenu);
    },
    showDrawerClose() {
      this.showmenu = false;
      this.$emit('show', this.showmenu);
    },
    onArrowClick() {
      if (this.carouselIndex < this.filters.length - 1) {
        this.carouselIndex += 1;
      } else {
        this.carouselIndex = 0;
      }
    },
  },
  beforeDestroy() {},
};
</script>
<style>
.yourewatching .carousel .carousel-indicator .indicator-item .indicator-style {
  width: 12px;
  height: 4px;
  border-radius: 2px;
  background-color: #c0c0c0c7;
  border: none;
}
.yourewatching .carousel .carousel-indicator .indicator-item:not(:last-child) {
  margin-right: 0.1rem;
}
.yourewatching .carousel .carousel-indicator .indicator-item .indicator-style:hover {
  background: white !important;
}
.yourewatching .carousel .carousel-indicator .indicator-item.is-active .indicator-style {
  background: #ffffff;
}
.yourewatching .carousel .carousel-indicator.is-inside.is-bottom {
  bottom: -3px;
}
@media screen and (max-width: 500px) {
  .yourewatching .carousel .carousel-indicator.is-inside.is-bottom {
    bottom: 35px;
  }
}
@media screen and (max-width: 400px) {
  .yourewatching .carousel .carousel-indicator.is-inside.is-bottom {
    bottom: 40px;
  }
}
@media screen and (max-width: 360px) {
  .yourewatching .carousel .carousel-indicator.is-inside.is-bottom {
    bottom: 24px;
  }
}
.yourewatching .carousel-arrow .icon.has-icons-right {
  display: none;
}
.yourewatching .carousel-arrow .icon.has-icons-left {
  display: none;
}
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: 100%;
}
</style>
<style lang="scss" scoped>
#after {
  cursor: pointer;
  border: 1px solid red;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 2.2em;
  height: 2.2em;
  background: rebeccapurple;
}
.tooltip-topscategory {
  width: calc(100% + 1px);
  height: 696px;
  background-color: rgba(0, 0, 0, 0.308);
  position: absolute;
  z-index: 3;
  top: -80px;
  right: -1px;
  @media screen and (max-width: 500px) {
    height: 780px;
  }
  &__container {
    width: 435px;
    height: 232px;
    padding: 14px 22px 35px;
    font-family: 'Avenir-Pro-Medium';
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border-radius: 12px;
    margin: 0 auto;
    margin-top: 90px;
    box-shadow: 0 14px 29px 0 rgba(0, 0, 0, 0.63);
    background-color: #252525ec;
    @media screen and (max-width: 500px) {
      width: 335px;
    }
    @media screen and (max-width: 380px) {
      width: 290px;
      height: 300px;
    }
    @media screen and (max-width: 330px) {
      width: 260px;
    }
    &__bold {
      font-family: 'Avenir-Pro-Bold';
      font-weight: bold;
      line-height: 1.83;
    }
  }
}
.container-slider {
  display: inline-block;
}
.container-slide-selected {
  display: none;
  @media screen and (max-width: 500px) {
    display: inline-block;
  }
}
.btnViewPlayersOrTeam {
  width: 275px;
  height: 35px;
  border-radius: 17.5px;
  margin-top: 5px;
  border: solid 2px #fff;
  display: none;
  font-family: 'Avenir-Pro-Bold';
  font-size: 18px;
  letter-spacing: -0.1px;
  text-align: center;
  color: #fff;
  @media screen and (max-width: 500px) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 400px) {
    width: 250px;
    font-size: 17px;
  }
  @media screen and (max-width: 320px) {
    width: 225px;
    font-size: 16px;
  }
}
.btnViewPlayersOrTeam:hover {
  opacity: 0.8;
  cursor: pointer;
}
.filterButton {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 2;
  display: none;
  @media screen and (max-width: 500px) {
    display: inline-block;
  }
}
.filterButton {
  opacity: 0.8;
  cursor: pointer;
}
.yourewatching {
  width: 100%;
  position: relative;
  height: 124px;
  background: rgb(7, 28, 87);
  background: linear-gradient(180deg, #075b89 0%, rgba(0, 62, 96, 0.74) 100%), #132839;
  @media screen and (max-width: 500px) {
    height: 206px;
  }
  &__item-carousel {
    width: 100%;
    height: 120px;
    @media screen and (max-width: 500px) {
      height: 202px;
    }
    &__title {
      font-family: 'Avenir-Demi';
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: -0.1px;
      text-align: center;
      color: #fff;
      display: inline-block;
      margin-top: 10px;
      width: 80%;
      height: 24px;
      opacity: 0.7;
      @media screen and (max-width: 500px) {
        font-size: 15px;
        margin-top: 27px;
        height: 30px;
        width: 100%;
      }
      //@media screen and (max-width: 400px) {
      //  font-size: 24px;
      //  margin-top: 27px;
      //  height: 30px;
      //  width: 100%;
      //}
      //@media screen and (max-width: 320px) {
      //  font-size: 20px;
      //  margin-top: 27px;
      //  height: 30px;
      //  width: 100%;
      //}
    }
    &__text {
      font-family: 'Avenir-Pro-Bold';
      font-stretch: normal;
      font-style: normal;
      font-size: 20px;
      letter-spacing: -0.11px;
      line-height: 21px;
      display: inline-block;
      color: #fff;
      margin-top: 3px;
      height: 45px;
      width: 560px;
      position: relative;
      @media screen and (max-width: 1220px) {
        width: 530px;
      }
      @media screen and (max-width: 1150px) {
        width: 500px;
      }
      @media screen and (max-width: 1080px) {
        width: 920px;
      }
      @media screen and (max-width: 1000px) {
        width: 850px;
      }
      @media screen and (max-width: 900px) {
        width: 800px;
      }
      @media screen and (max-width: 850px) {
        width: 750px;
      }
      @media screen and (max-width: 800px) {
        width: 700px;
      }
      @media screen and (max-width: 750px) {
        width: 650px;
      }
      @media screen and (max-width: 700px) {
        width: 600px;
      }
      @media screen and (max-width: 650px) {
        width: 550px;
      }
      @media screen and (max-width: 600px) {
        width: 500px;
      }
      @media screen and (max-width: 550px) {
        width: 470px;
      }
      @media screen and (max-width: 500px) {
        font-size: 22px;
        width: 430px;
        height: 84px;
        margin-top: 6px;
        line-height: 28px;
      }
      @media screen and (max-width: 470px) {
        width: 400px;
      }
      @media screen and (max-width: 440px) {
        width: 370px;
        font-size: 21px;
        line-height: 26px;
        height: 80px;
      }
      @media screen and (max-width: 410px) {
        width: 340px;
      }
      @media screen and (max-width: 380px) {
        width: 320px;
      }
      @media screen and (max-width: 360px) {
        width: 300px;
        font-size: 18px;
        line-height: 24px;
        height: 94px;
      }
      @media screen and (max-width: 340px) {
        width: 280px;
      }
      @media screen and (max-width: 320px) {
        width: 260px;
      }
      &__color {
        color: #ffff03;
      }
    }

    &__indicator {
      font-family: 'Avenir-Pro-Medium';
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: -0.08px;
      text-align: center;
      color: #fff;
      width: 100%;
      height: 20px;
      display: flex;
      margin-top: -2px !important;
      align-items: center;
      justify-content: center;
      padding-left: 40px;

      & > img {
        cursor: pointer;
      }
      @media screen and (max-width: 500px) {
        font-size: 19px;
        width: 100%;
        margin-top: 16px !important;
        padding-left: 42px;
      }
      @media screen and (max-width: 400px) {
        font-size: 15px;
        width: 100%;
        margin-top: 20px !important;
        padding-left: 37px;
      }
      @media screen and (max-width: 360px) {
        font-size: 13px;
        width: 100%;
        margin-top: 20px !important;
        padding-left: 37px;
      }
      @media screen and (max-width: 320px) {
        font-size: 13px;
        width: 100%;
        padding-left: 37px;
      }
      img {
        transform: rotate(180deg);
        display: inline-block;
        margin-left: 15px;
        @media screen and (max-width: 500px) {
          width: 30px !important;
        }
        @media screen and (max-width: 400px) {
          width: 22px !important;
        }
        @media screen and (max-width: 320px) {
          width: 19px !important;
        }
      }
    }
  }
}
</style>
