<template>
  <section class="topf-container">
    <Component :is="'style'">
      .topf-container__tabs > span.active, .topf-container__tabs > span:hover { background-color:
      {{ customValues.bgColor || '#d2ebbd' }}; } .local-btns > button.active, .result-btns > button.active, .time-btns >
      button.active { background-color: {{ customValues.bgColor || '#d2ebbd' }}; } .local-btns > button, .result-btns >
      button, .time-btns > button { background-color: #ffffff; }
    </Component>
    <div class="topf-container__body" :class="{ 'display-right': tab === 1 }">
      <div class="tab-zone">
        <div class="tab-zone__legend">
          <span>Elige el cuadro o zona de cancha:</span>
        </div>
        <div class="tab-zone__map">
          <img src="/top/cancha/cancha.png" alt="cancha" class="field" style="visibility: hidden;" />
          <div class="zona-map-controller">
            <div>
              <img src="/images/blue_arrow.svg" alt="cancha" class="field-arrow" />
              <template v-for="(sector, index) in fieldsector">
                <div
                  :key="index"
                  class="zona-map-controller__sector-map"
                  :class="[sector.status ? 'selected' : '', system == 'unknown' ? 'sector-map-hover' : '']"
                  @click="selectFieldSector(index, sector.num)"
                >
                  <div />
                </div>
              </template>
            </div>
          </div>
          <div class="checkbox-controllers">
            <div style="margin-top:12px">
              <div class="checkbox-item" :class="checkbox44 ? 'selected' : ''" @click="selectFieldSector1">4/4tos</div>
            </div>
            <div style="margin-top:4px">
              <div class="checkbox-item" :class="checkbox34 ? 'selected' : ''" @click="selectFieldSector2">3/4tos</div>
            </div>
            <div style="margin-top:2px">
              <div class="checkbox-item" :class="checkbox24 ? 'selected' : ''" @click="selectFieldSector3">2/4tos</div>
            </div>
            <div style="margin-top:2px">
              <div class="checkbox-item" :class="checkbox14 ? 'selected' : ''" @click="selectFieldSector4">
                1/4tos
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-advanced">
        <div class="tab-advanced__local" :style="cupStyle">
          <div class="label-advanced-titles">Jugando como:</div>
          <div class="local-btns">
            <button @click="advancedSearch(1, local)" :class="{ active: local.includes(1) }">LOCAL</button>
            <button @click="advancedSearch(2, local)" :class="{ active: local.includes(2) }">VISITANTE</button>
          </div>
        </div>

        <div class="tab-advanced__first-time">
          <div class="label-advanced-titles first-time-flag">
            En:
            <span class="label-advanced-titles__italic">(Elige el lapso de tiempo)</span>
          </div>
          <label for="first-time">1er Tiempo:</label>
          <div class="time-btns">
            <button @click="advancedSearch(1, firstTime)" :class="{ active: firstTime.includes(1) }">
              0 - 15
            </button>
            <button @click="advancedSearch(2, firstTime)" :class="{ active: firstTime.includes(2) }">
              16 - 30
            </button>
            <button @click="advancedSearch(3, firstTime)" :class="{ active: firstTime.includes(3) }">
              31 - 45
            </button>
          </div>
        </div>
        <div class="tab-advanced__second-time">
          <label for="second-time">2do Tiempo:</label>
          <div class="time-btns">
            <button @click="advancedSearch(4, firstTime)" :class="{ active: firstTime.includes(4) }">
              46 - 60
            </button>
            <button @click="advancedSearch(5, firstTime)" :class="{ active: firstTime.includes(5) }">
              61 - 75
            </button>
            <button @click="advancedSearch(6, firstTime)" :class="{ active: firstTime.includes(6) }">
              76 - 90
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="switch-type-filter-container ">
      <div class="switch-type-filter-container__title">Filtrar:</div>
      <div class="switch-type-filter-container__switch">
        <BField>
          <span class="switch-type-filter-container__switch__option flag-option">Zona de cancha</span>
          <BSwitch inset size="is-medium" color="secondary" v-model="isSwitchedSection"></BSwitch>
          <span class="switch-type-filter-container__switch__option flag-option-2">Avanzado</span>
        </BField>
      </div>
    </div>
  </section>
</template>

<script>
import { clickFiltrarTops, clickFiltrarAvanzadoTops } from '@/utils/analytics';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { BField } from 'buefy/dist/esm/field';
import { BSwitch } from 'buefy/dist/esm/switch';
export default {
  components: {
    BField,
    BSwitch,
  },
  props: {
    customValues: {
      type: Object,
      default: () => {},
    },
    filtersPredefined: {
      type: Object,
      default: () => {},
    },
    category: {
      type: Number,
      require: true,
    },
    type: {
      type: Number,
      require: true,
    },
    isCup: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.system = this.getMobileOperatingSystem();
  },
  computed: {
    ...mapState('general', ['tournamentSelected']),
    ...mapState({ displayWidth: 'displayWidth' }),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'isLoggedIn', 'getIsGolstats']),
    cupStyle() {
      const cupStyle = {};
      if (this.isCup) {
        cupStyle.display = 'none';
      }
      return cupStyle;
    },
    checkbox14() {
      return this.selectFieldGroupSector4;
    },
    checkbox24() {
      return this.selectFieldGroupSector3;
    },
    checkbox34() {
      return this.selectFieldGroupSector2;
    },
    checkbox44() {
      return this.selectFieldGroupSector1;
    },
  },
  watch: {
    isSwitchedSection() {
      if (this.isSwitchedSection) {
        this.tab = 1;
      } else {
        this.tab = 0;
      }
    },
    filtersPredefined: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (Object.keys(newValue).length > 0) {
          this.local = newValue.playingAs;
          this.results = newValue.matchResult;
          this.firstTime = newValue.sections;
          this.zoneType = newValue.typeZone;
          for (let index = 0; index < this.fieldsector.length; index++) {
            if (newValue.zone.includes(this.fieldsector[index].num)) {
              this.fieldsector[index].status = true;
            } else {
              this.fieldsector[index].status = false;
            }
            this.validateField(index);
          }
          this.zone = newValue.zone;
        }
      },
    },
  },
  data: () => ({
    isSwitchedSection: false,
    radio: 'Silver',
    zoneType: 1,
    updateCheck: 0,
    tab: 0,
    local: [1, 2],
    results: [1, 2, 3],
    firstTime: [1, 2, 3, 4, 5, 6],
    secondTime: [1, 2],
    zone: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    areas: {
      13: 'A',
      14: 'B',
      15: 'C',
      16: 'D',
      9: 'E',
      10: 'F',
      11: 'G',
      12: 'H',
      5: 'I',
      6: 'J',
      7: 'K',
      8: 'L',
      1: 'M',
      2: 'N',
      3: 'O',
      4: 'P',
    },
    fieldsector: [
      { num: 13, status: true },
      { num: 14, status: true },
      { num: 15, status: true },
      { num: 16, status: true },
      { num: 9, status: true },
      { num: 10, status: true },
      { num: 11, status: true },
      { num: 12, status: true },
      { num: 5, status: true },
      { num: 6, status: true },
      { num: 7, status: true },
      { num: 8, status: true },
      { num: 1, status: true },
      { num: 2, status: true },
      { num: 3, status: true },
      { num: 4, status: true },
    ],
    selectFieldGroupSector1: true,
    selectFieldGroupSector2: true,
    selectFieldGroupSector3: true,
    selectFieldGroupSector4: true,
    group1: [16, 15, 14, 13],
    group2: [12, 11, 10, 9],
    group3: [8, 7, 6, 5],
    group4: [4, 3, 2, 1],
  }),
  methods: {
    ...mapMutations('general', ['setSectionAnalytics', 'setPreviewSectionAnalytics']),
    ...mapMutations(['SET_PREMIUM_MESSAGE']),
    updateCheckF() {
      this.updateCheck += 1;
    },
    advancedType(type) {
      const value = this.local.findIndex(r => r === type);
      value === -1 ? this.local.push(type) : this.local.splice(value, 1);
    },
    validateField(index) {
      if (index == 0 || index == 1 || index == 2 || index == 3) {
        if (
          this.fieldsector[0].status &&
          this.fieldsector[1].status &&
          this.fieldsector[2].status &&
          this.fieldsector[3].status
        ) {
          this.selectFieldGroupSector1 = true;
        } else {
          this.selectFieldGroupSector1 = false;
        }
      }
      if (index == 4 || index == 5 || index == 6 || index == 7) {
        if (
          this.fieldsector[4].status &&
          this.fieldsector[5].status &&
          this.fieldsector[6].status &&
          this.fieldsector[7].status
        ) {
          this.selectFieldGroupSector2 = true;
        } else {
          this.selectFieldGroupSector2 = false;
        }
      }
      if (index == 8 || index == 9 || index == 10 || index == 11) {
        if (
          this.fieldsector[8].status &&
          this.fieldsector[9].status &&
          this.fieldsector[10].status &&
          this.fieldsector[11].status
        ) {
          this.selectFieldGroupSector3 = true;
        } else {
          this.selectFieldGroupSector3 = false;
        }
      }
      if (index == 12 || index == 13 || index == 14 || index == 15) {
        if (
          this.fieldsector[12].status &&
          this.fieldsector[13].status &&
          this.fieldsector[14].status &&
          this.fieldsector[15].status
        ) {
          this.selectFieldGroupSector4 = true;
        } else {
          this.selectFieldGroupSector4 = false;
        }
      }
    },

    selectFieldSector(index, num) {
      if (this.isLoggedIn) {
        if (!this.fieldsector[index].status) {
          this.fieldsector[index].status = true;
        } else {
          if (this.zone.length > 1) {
            this.fieldsector[index].status = false;
          }
        }
        const value = this.zone.indexOf(num);
        value === -1 ? this.zone.push(num) : this.zone.length > 1 ? this.zone.splice(value, 1) : null;
        this.validateField(index);
        this.filterOnDesktop(false);
      } else {
        this.setPreviewSectionAnalytics('');
        this.setSectionAnalytics('Popup_AF_Tops');
        this.SET_PREMIUM_MESSAGE(true);
      }
    },
    selectFieldSector1() {
      if (this.isLoggedIn) {
        if (!this.selectFieldGroupSector1) {
          this.group1.map(index => {
            const found = this.fieldsector.findIndex(element => element.num == index);
            this.fieldsector[found].status = true;
          });
          this.group1.forEach(zone => {
            let section = zone;
            let value = this.zone.indexOf(section);
            value === -1 ? this.zone.push(section) : null;
          });
        } else {
          if (this.selectFieldGroupSector2 || this.selectFieldGroupSector3 || this.selectFieldGroupSector4) {
            this.group1.map(index => {
              const found = this.fieldsector.findIndex(element => element.num == index);
              this.fieldsector[found].status = false;
            });
            this.group1.forEach(zone => {
              let section = zone;
              let value = this.zone.indexOf(section);
              value === -1 ? null : this.zone.splice(value, 1);
            });
          } else {
            if (this.zone.length > 4) {
              this.group1.map(index => {
                const found = this.fieldsector.findIndex(element => element.num == index);
                this.fieldsector[found].status = false;
              });
              this.group1.forEach(zone => {
                let section = zone;
                let value = this.zone.indexOf(section);
                value === -1 ? null : this.zone.splice(value, 1);
              });
            } else {
              this.selectFieldGroupSector1 = true;
              this.updateCheckF();
            }
          }
        }

        this.filterOnDesktop(false);
        this.selectFieldGroupSector1 = !this.selectFieldGroupSector1;
      } else {
        this.setPreviewSectionAnalytics('');
        this.setSectionAnalytics('Popup_AF_Tops');
        this.SET_PREMIUM_MESSAGE(true);
      }
    },
    selectFieldSector2() {
      if (this.isLoggedIn) {
        if (!this.selectFieldGroupSector2) {
          this.group2.map(index => {
            const found = this.fieldsector.findIndex(element => element.num == index);
            this.fieldsector[found].status = true;
          });
          this.group2.forEach(zone => {
            let section = zone;
            let value = this.zone.indexOf(section);
            value === -1 ? this.zone.push(section) : null;
          });
        } else {
          if (this.selectFieldGroupSector1 || this.selectFieldGroupSector3 || this.selectFieldGroupSector4) {
            this.group2.map(index => {
              const found = this.fieldsector.findIndex(element => element.num == index);
              this.fieldsector[found].status = false;
            });
            this.group2.forEach(zone => {
              let section = zone;
              let value = this.zone.indexOf(section);
              value === -1 ? null : this.zone.splice(value, 1);
            });
          } else {
            if (this.zone.length > 4) {
              this.group2.map(index => {
                const found = this.fieldsector.findIndex(element => element.num == index);
                this.fieldsector[found].status = false;
              });
              this.group2.forEach(zone => {
                let section = zone;
                let value = this.zone.indexOf(section);
                value === -1 ? null : this.zone.splice(value, 1);
              });
            } else {
              this.selectFieldGroupSector2 = true;
              this.updateCheckF();
            }
          }
        }
        this.filterOnDesktop(false);
        this.selectFieldGroupSector2 = !this.selectFieldGroupSector2;
      } else {
        this.setPreviewSectionAnalytics('');
        this.setSectionAnalytics('Popup_AF_Tops');
        this.SET_PREMIUM_MESSAGE(true);
      }
    },
    selectFieldSector3() {
      if (this.isLoggedIn) {
        if (!this.selectFieldGroupSector3) {
          this.group3.map(index => {
            const found = this.fieldsector.findIndex(element => element.num == index);
            this.fieldsector[found].status = true;
          });
          this.group3.forEach(zone => {
            let section = zone;
            let value = this.zone.indexOf(section);
            value === -1 ? this.zone.push(section) : null;
          });
        } else {
          if (this.selectFieldGroupSector2 || this.selectFieldGroupSector1 || this.selectFieldGroupSector4) {
            this.group3.map(index => {
              const found = this.fieldsector.findIndex(element => element.num == index);
              this.fieldsector[found].status = false;
            });
            this.group3.forEach(zone => {
              let section = zone;
              let value = this.zone.indexOf(section);
              value === -1 ? null : this.zone.splice(value, 1);
            });
          } else {
            if (this.zone.length > 4) {
              this.group3.map(index => {
                const found = this.fieldsector.findIndex(element => element.num == index);
                this.fieldsector[found].status = false;
              });
              this.group3.forEach(zone => {
                let section = zone;
                let value = this.zone.indexOf(section);
                value === -1 ? null : this.zone.splice(value, 1);
              });
            } else {
              this.selectFieldGroupSector3 = true;
              this.updateCheckF();
            }
          }
        }
        this.filterOnDesktop(false);
        this.selectFieldGroupSector3 = !this.selectFieldGroupSector3;
      } else {
        this.setPreviewSectionAnalytics('');
        this.setSectionAnalytics('Popup_AF_Tops');
        this.SET_PREMIUM_MESSAGE(true);
      }
    },
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
      }

      if (/android/i.test(userAgent)) {
        return 'Android';
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }

      return 'unknown';
    },
    selectFieldSector4() {
      if (this.isLoggedIn) {
        if (!this.selectFieldGroupSector4) {
          this.group4.map(index => {
            const found = this.fieldsector.findIndex(element => element.num == index);
            this.fieldsector[found].status = true;
          });
          this.group4.forEach(zone => {
            let section = zone;
            let value = this.zone.indexOf(section);
            value === -1 ? this.zone.push(section) : null;
          });
        } else {
          if (this.selectFieldGroupSector2 || this.selectFieldGroupSector1 || this.selectFieldGroupSector3) {
            this.group4.map(index => {
              const found = this.fieldsector.findIndex(element => element.num == index);
              this.fieldsector[found].status = false;
            });
            this.group4.forEach(zone => {
              let section = zone;
              let value = this.zone.indexOf(section);
              value === -1 ? null : this.zone.splice(value, 1);
            });
          } else {
            if (this.zone.length > 4) {
              this.group4.map(index => {
                const found = this.fieldsector.findIndex(element => element.num == index);
                this.fieldsector[found].status = false;
              });
              this.group4.forEach(zone => {
                let section = zone;
                let value = this.zone.indexOf(section);
                value === -1 ? null : this.zone.splice(value, 1);
              });
            } else {
              this.selectFieldGroupSector4 = true;
              this.updateCheckF();
            }
          }
        }
        this.filterOnDesktop(false);
        this.selectFieldGroupSector4 = !this.selectFieldGroupSector4;
      } else {
        this.setPreviewSectionAnalytics('');
        this.setSectionAnalytics('Popup_AF_Tops');
        this.SET_PREMIUM_MESSAGE(true);
      }
    },
    advancedSearch(type, arr) {
      if (this.isLoggedIn) {
        const value = arr.findIndex(r => r === type);
        value === -1 ? arr.push(type) : arr.length > 1 ? arr.splice(value, 1) : null;
        this.filterOnDesktop(true);
      } else {
        this.setPreviewSectionAnalytics('');
        this.setSectionAnalytics('Popup_AF_Tops');
        this.SET_PREMIUM_MESSAGE(true);
      }
    },
    buttonfilterZona() {
      var type = 'movil';
      if (this.displayWidth > 500) {
        type = 'pc';
      }
      if (this.getPremiumAccount) {
        var filterAvance = {
          type: 1,
          sections: this.firstTime,
          matchResult: this.results,
          playingAs: this.local,
          zone: this.zone,
          typeZone: this.zoneType,
        };
        this.$emit('filters', filterAvance, type);
      } else {
        this.SET_PREMIUM_MESSAGE(true);
      }
      clickFiltrarTops({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
    },
    buttonfilter() {
      var type = 'movil';
      if (this.displayWidth > 500) {
        type = 'pc';
      }

      if (this.getPremiumAccount) {
        var filterAvance = {
          type: 1,
          sections: this.firstTime,
          matchResult: this.results,
          playingAs: this.local,
          zone: this.zone,
          typeZone: this.zoneType,
        };
        this.$emit('filters', filterAvance, type);
      } else {
        this.SET_PREMIUM_MESSAGE(true);
      }
      clickFiltrarAvanzadoTops({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
    },
    filterOnDesktop(advanced) {
      if (advanced) {
        this.buttonfilter();
      } else {
        this.buttonfilterZona();
      }
    },
  },
};
</script>
<style lang="scss">
.topf-container {
  .menu-top .switch input[type='checkbox'] + .check.is-success-passive,
  .menu-top .switch input[type='checkbox'] + .check:hover {
    background: #cbee6b !important;
  }
  .switch input[type='checkbox'] + .check.is-success-passive,
  .switch input[type='checkbox'] + .check:hover,
  .switch input[type='checkbox']:checked + .check:hover {
    background: #cbee6b !important;
    box-shadow: none !important;
  }
  .switch.is-rounded input[type='checkbox'] + .check:before {
    background: #132839 !important;
    box-shadow: none !important;
  }
  .switch input[type='checkbox'] + .check {
    background: #cbee6b !important;
    box-shadow: none !important;
  }
}
</style>
<style lang="scss" scoped>
.first-time-flag {
  margin-bottom: 11px;
}
.label-advanced-titles {
  font-family: 'Avenir-Demi';
  font-size: 14.7px;
  text-align: left;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.08px;
  color: #132839;
  &__italic {
    font-family: 'Avenir-Pro-Medium';
    font-style: italic;
    @media screen and (max-width: 1143px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1110px) {
      font-size: 13.5px;
    }
    @media screen and (max-width: 1087px) {
      font-size: 13px;
    }
    @media screen and (max-width: 1080px) {
      font-size: 14.7px;
    }
    @media screen and (max-width: 562px) {
      font-size: 14px;
    }
    @media screen and (max-width: 545px) {
      font-size: 13.5px;
    }
    @media screen and (max-width: 534px) {
      font-size: 13px;
    }
    @media screen and (max-width: 522px) {
      font-size: 12.5px;
    }
    @media screen and (max-width: 510px) {
      font-size: 12px;
    }
    @media screen and (max-width: 500px) {
      font-size: 14.7px;
    }
  }
}
.flag-option-2 {
  margin-left: -15px;
}
.flag-option {
  width: 100px !important;
  margin-right: 5px;
  margin-left: -17px;
}
.switch-type-advanced {
  margin-top: 330px !important;
}
.switch-type-filter-container {
  width: 100%;
  height: 50px;
  margin-top: -20px;
  text-align: center;
  display: inline-block;
  &__title {
    width: 100%;
    height: 25px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.93;
    letter-spacing: -0.08px;
    text-align: center;
    color: #132839;
    font-family: 'Avenir-Pro-Bold';
    padding-left: 12px;
  }
  &__switch {
    width: 220px;
    height: 30px;
    margin: 0 auto;
    &__option {
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-family: 'Avenir-Pro-Medium';
      font-style: normal;
      line-height: 1.93;
      letter-spacing: -0.08px;
      text-align: center;
      color: #132839;
      line-height: 30px;
      @media screen and (max-width: 570px) {
        font-size: 11px;
      }
      @media screen and (max-width: 526px) {
        font-size: 9px;
      }
      @media screen and (max-width: 500px) {
        font-size: 13px;
      }
    }
  }
}
.checkbox-item {
  width: 56px;
  height: 27px;
  margin: 0 0 1px;
  border-radius: 4px;
  border: solid 1px #cbee6b;
  font-size: 12px;
  font-family: 'Avenir-Demi';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: -0.07px;
  color: #132839;
  @media screen and (max-width: 550px) {
    width: 51px;
  }
  @media screen and (max-width: 500px) {
    width: 56px;
  }
}
.checkbox-item:hover {
  color: #132839;
  background-color: #cbee6b;
  cursor: pointer;
  @media screen and (max-width: 500px) {
    border: solid 1px #3c6eb5;
    background: none;
    color: #3c6eb5;
  }
}
.checkbox-item.selected {
  color: #132839 !important;
  border: solid 1px #cbee6b;
  background-color: #cbee6b !important;
}
.sector-map-hover:hover {
  cursor: pointer;

  & > div {
    background-color: #cbee6b;
    opacity: 0.2;
  }
}
.zona-map-controller {
  background-image: url('/top/cancha/cancha.png');
  background-repeat: no-repeat;
  background-size: 100% 102%;
  background-position-y: -3px;
  height: calc(100% - 3.5px);
  width: 100%;
  max-width: 159px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: solid 2px #cbee6b;

  & > div {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    position: relative;

    & > img.field-arrow {
      position: absolute;
      height: 99%;
      left: 46%;
      z-index: 1;
    }
  }

  @media screen and (max-width: 1230px) {
    margin-left: -10px;
  }
  @media screen and (max-width: 1180px) {
    margin-left: -15px;
  }
  @media screen and (max-width: 1110px) {
    margin-left: -20px;
  }
  @media screen and (max-width: 1080px) {
    margin-left: 0px;
  }
  @media screen and (max-width: 630px) {
    margin-left: -6px;
  }
  @media screen and (max-width: 570px) {
    margin-left: -13px;
  }
  @media screen and (max-width: 547px) {
    margin-left: -21px;
  }
  @media screen and (max-width: 500px) {
    margin-left: 0px;
  }
  // margin-left: -10px;
  &__sector-map {
    border: solid 1.5px #cbee6b;
    text-align: center;
    line-height: 63px;
    float: left;
    font-family: 'Avenir-Demi';
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #084f60;

    & > div {
      height: 100%;
      width: 100%;
    }
  }

  &__sector-map.selected {
    & > div {
      background-color: #cbee6b;
      opacity: 0.2;
    }
  }
}
.topf-container {
  // background: #aa025e;
  overflow: hidden;
  height: 100%;
  padding-bottom: 10px;
  // background: #7ed321;
  width: 100%;
  &__header {
    display: inline-block;
    margin-top: 5px;
    font-family: 'Avenir-Demi';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -0.09px;
    text-align: center;
    color: #525252;
    @media screen and (max-width: 1115px) {
      font-size: 15px;
    }
    @media screen and (max-width: 1080px) {
      font-size: 16px;
    }
    @media screen and (max-width: 560px) {
      font-size: 15px;
    }
    @media screen and (max-width: 520px) {
      font-size: 14px;
    }
    @media screen and (max-width: 500px) {
      font-size: 21px;
    }
    @media screen and (max-width: 360px) {
      font-size: 18px;
    }
    @media screen and (max-width: 330px) {
      font-size: 16px;
    }
  }
  &__tabs {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-left: 30px;
    padding: 10px 0px;
    span {
      width: 86px;
      height: 20px;
      border-radius: 10px;
      background-color: #ffffff;
      font-family: 'Circular-Std-Medium';
      font-size: 12px;
      letter-spacing: 0.22px;
      text-align: center;
      color: #353535;
      cursor: pointer;
      transition: all 0.5s ease;
    }
  }
  &__body {
    display: flex;
    transition: all 0.6s ease;
    //height: calc(100% - 40px);
    &.display-right {
      transform: translateX(-100%);
    }
  }
}
.tab-zone {
  position: relative;
  min-width: 100%;
  display: grid;

  grid-template-columns: auto;
  grid-template-areas: 'legend' 'map' 'select' 'main-btn';
  grid-template-rows: auto auto 40px auto;
  align-items: center;

  &__arrow {
    width: 100%;
    max-width: 20px;
    height: 80%;
    position: absolute;
    left: 25px;
    top: 0;
    bottom: 0;
    margin: auto;
    @media screen and (max-width: 1300px) {
      left: 20px;
    }
    @media screen and (max-width: 1200px) {
      left: 12px;
    }
    @media screen and (max-width: 1150px) {
      left: 10px;
    }
    @media screen and (max-width: 1130px) {
      left: 0px;
    }
    @media screen and (max-width: 1080px) {
      left: 109px;
    }
    @media screen and (max-width: 1030px) {
      left: 122px;
    }
    @media screen and (max-width: 1000px) {
      left: 103px;
    }
    @media screen and (max-width: 910px) {
      left: 98px;
    }
    @media screen and (max-width: 865px) {
      left: 80px;
    }
    @media screen and (max-width: 830px) {
      left: 70px;
    }
    @media screen and (max-width: 810px) {
      left: 65px;
    }
    @media screen and (max-width: 730px) {
      left: 55px;
    }
    @media screen and (max-width: 680px) {
      left: 40px;
    }
    @media screen and (max-width: 640px) {
      left: 28px;
    }
    @media screen and (max-width: 580px) {
      left: 12px;
    }
    @media screen and (max-width: 546px) {
      left: 0px;
    }
    @media screen and (max-width: 528px) {
      left: -4px;
    }
    @media screen and (max-width: 500px) {
      left: 118px;
    }
    @media screen and (max-width: 480px) {
      left: 103px;
    }
    @media screen and (max-width: 445px) {
      left: 90px;
    }
    @media screen and (max-width: 425px) {
      left: 75px;
    }
    @media screen and (max-width: 390px) {
      left: 60px;
    }
    @media screen and (max-width: 360px) {
      left: 45px;
    }
    @media screen and (max-width: 330px) {
      left: 30px;
    }
  }
  &__map {
    grid-area: map;
    position: relative;
    height: 100%;
    width: 100%;
    float: right;
    grid-template-columns: auto;
    grid-template-areas: 'legend' 'map' 'select' 'main-btn';
    grid-template-rows: auto auto 40px auto;
    // background: blue;
    margin-top: 12px;
    margin-right: 12px;
    img.field {
      height: 100%;
      object-fit: contain;
    }

    .checkbox-controllers {
      position: absolute;
      top: -5px;
      width: 56px;
      right: 0px;
      // background: #08cf3a;
      display: flex;
      flex-flow: column;
      justify-content: space-around;
      height: 100%;
      // @media screen and (max-width: 1280px) {
      //   right: 0px;
      // }
      // @media screen and (max-width: 1180px) {
      //   right: 0px;
      // }
      @media screen and (max-width: 1080px) {
        right: 20%;
      }
      @media screen and (max-width: 1000px) {
        right: 17%;
      }
      @media screen and (max-width: 910px) {
        right: 13%;
      }
      @media screen and (max-width: 800px) {
        right: 7%;
      }
      @media screen and (max-width: 700px) {
        right: 4%;
      }
      @media screen and (max-width: 650px) {
        right: 1%;
      }
      @media screen and (max-width: 590px) {
        right: 0%;
      }
      @media screen and (max-width: 550px) {
        right: -2%;
      }
      @media screen and (max-width: 500px) {
        right: 17%;
      }
      @media screen and (max-width: 460px) {
        right: 14%;
      }
      @media screen and (max-width: 410px) {
        right: 11%;
      }
      @media screen and (max-width: 380px) {
        right: 8%;
      }
      @media screen and (max-width: 350px) {
        right: 4%;
      }
      @media screen and (max-width: 320px) {
        right: 0.2%;
      }
    }
  }
  &__legend {
    grid-area: legend;
    span {
      display: block;
      margin: 58px 0 18px;
      width: 100%;
      font-family: 'Avenir-Demi';
      font-size: 14px;
      float: right;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      // margin-right: 17px;
      line-height: 1.33;
      letter-spacing: -0.07px;
      color: #132839;
      //@media screen and (max-width: 1080px) {
      //  padding-right: 20%;
      //}
      //@media screen and (max-width: 1000px) {
      //  padding-right: 17%;
      //}
      //@media screen and (max-width: 910px) {
      //  padding-right: 13%;
      //}
      //@media screen and (max-width: 800px) {
      //  padding-right: 7%;
      //}
      //@media screen and (max-width: 700px) {
      //  padding-right: 4%;
      //}
      //@media screen and (max-width: 650px) {
      //  padding-right: 1%;
      //}
      //@media screen and (max-width: 590px) {
      //  padding-right: 0%;
      //}
      //@media screen and (max-width: 550px) {
      //  padding-right: -2%;
      //}
      //@media screen and (max-width: 500px) {
      //  padding-right: 17%;
      //  font-size: 12px;
      //}
      //@media screen and (max-width: 460px) {
      //  padding-right: 14%;
      //}
      //@media screen and (max-width: 410px) {
      //  padding-right: 11%;
      //}
      //@media screen and (max-width: 380px) {
      //  padding-right: 8%;
      //}
      //@media screen and (max-width: 350px) {
      //  padding-right: 4%;
      //}
      //@media screen and (max-width: 320px) {
      //  padding-right: 2%;
      //}
    }
  }
  &__select {
    grid-area: select;
    margin-top: 12px;
  }
  &__main-btn {
    grid-area: main-btn;
    display: none;
    @media screen and (max-width: 500px) {
      display: block;
    }

    button {
      width: 150px;
      height: 35px;
      margin: 8px 0px 0px 0px;
      border-radius: 5px;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(to bottom, #7ed321, #6dc111), linear-gradient(to bottom, #7ed321, #7ed321);
      border: none;
      outline: none;
      color: white;
      cursor: pointer;
    }
  }
}
.tab-advanced {
  min-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'trnmnt trnmnt' 'lcl rslt' 'fsttm fsttm' 'sndtm sndtm' 'mbtn mbtn';
  align-items: flex-start;
  padding: 0px 30px;
  column-gap: 20px;
  grid-template-rows: 35px 60px 60px auto 60px;
  @media screen and (max-width: 500px) {
    grid-template-rows: 25px 60px 60px auto 60px;
  }
  &__tournament {
    grid-area: trnmnt;
    font-size: 10px;
    letter-spacing: -0.03px;
    text-align: left;
    color: #494a4b;
  }
  &__local {
    grid-area: lcl;
    font-size: 10px;
    letter-spacing: -0.03px;
    text-align: center;
    color: #132839;

    .local-btns {
      display: grid;
      width: 120px;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      button {
        border: solid 1px #b0d546;
        outline: none;
        height: 31px;
        width: 60px;
        font-family: 'Circular-Std';
        font-size: 9px;
        letter-spacing: -0.03px;
        text-align: center;
        line-height: 29px;
        color: #132839;
        cursor: pointer;
        transition: all 0.5s ease;
        &:first-child {
          border-radius: 4px 0px 0px 4px;
        }
        &:last-child {
          border-radius: 0px 4px 4px 0px;
        }
      }
    }
  }
  &__result {
    grid-area: rslt;
    font-size: 10px;
    letter-spacing: -0.03px;
    text-align: center;
    color: #494a4b;

    .result-btns {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 5px;

      button {
        height: 28px;
        border: solid 1px #7c7c7c;
        outline: none;
        border-radius: 4.8px;
        font-family: 'Circular-Std-Medium';
        font-size: 12px;
        letter-spacing: -0.03px;
        text-align: center;
        color: #494a4b;
        cursor: pointer;
        transition: all 0.5s ease;
        @media screen and (max-width: 1033px) {
          font-size: 11px;
          padding-right: 13px !important;
        }
        @media screen and (max-width: 1000px) {
          font-size: 12px;
          padding-right: 4px !important;
        }
      }
    }
  }
  &__first-time {
    grid-area: fsttm;
    margin-top: 47px;
  }
  &__second-time {
    grid-area: sndtm;
    margin-top: 80px;
  }
  &__first-time,
  &__second-time {
    text-align: left;
    font-size: 10px;
    letter-spacing: -0.03px;
    color: #132839;

    label {
      padding-left: 5px;
    }
    .time-btns {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));

      button {
        border: solid 1px #b0d546;
        outline: none;
        height: 28px;
        font-family: 'Circular-Std-Medium';
        font-size: 12px;
        letter-spacing: -0.03px;
        text-align: center;
        color: #132839;
        cursor: pointer;
        transition: all 0.5s ease;

        &:first-child {
          border-radius: 4px 0px 0px 4px;
        }
        &:last-child {
          border-radius: 0px 4px 4px 0px;
        }
        @media screen and (max-width: 1033px) {
          font-size: 11px;
        }
        @media screen and (max-width: 1000px) {
          font-size: 12px;
        }
      }
    }
  }

  &__main-btn {
    grid-area: mbtn;
    align-self: center;
    display: none;
    @media screen and (max-width: 500px) {
      display: block;
    }

    button {
      width: 150px;
      height: 35px;
      margin: 20px 0px;
      border-radius: 5px;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(to bottom, #7ed321, #6dc111), linear-gradient(to bottom, #7ed321, #7ed321);
      border: none;
      outline: none;
      color: white;
      cursor: pointer;
    }
  }
  .radio-select-1 {
    margin-top: 15px;
    float: left;
  }
}
</style>
